EntityModule.registerInitializer(function (node) {
  const $actionTypeSelector = $("div[data-em-property='workflowActionFields[].fieldType']", node);
  $actionTypeSelector.each(function () {
    var selectedValue = $(this).find(":selected").val();
    showOrHideOptions(selectedValue, $(this).parent());
    $(this).change(function () {
      selectedValue = $(this).find(":selected").val();
      showOrHideOptions(selectedValue, $(this).parent());
    });
  });
});

function showOrHideOptions(selectedValue, node) {
  if (selectedValue !== "SELECT") {
    $("div[data-em-property='workflowActionFields[].options']", node).hide();
  } else {
    $("div[data-em-property='workflowActionFields[].options']", node).show();
  }
}
