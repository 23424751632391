import { convertResponseToJson, getCookie, handleError } from "./utils";
import i18next from "../i18n";
import { updatePropertyData } from "./editable-value";

EntityModule.registerInitializer(function (node) {
  initializeAssetTrackingButtons(node);
});

export function initializeAssetTrackingButtons(node) {
  $(".js-asset-tracking-button", node).each(initializeAssetTrackingButton);
}

function initializeAssetTrackingButton(index, node) {
  node.addEventListener("click", showAssetTrackingModal);
}

function initializeUrlFields(node) {
  $(".url-field", node).each(initializeUrlField);
  $("[data-action='remove-item']", node).each(initializeRemoveButton);
}

function initializeUrlField(index, node) {
  node.addEventListener("blur", validateUrlField);
}

function initializeRemoveButton(index, node) {
  node.addEventListener("click", evaluateAssetTrackingForm);
}

function evaluateAssetTrackingForm() {
  const $form = $("#assetTrackingForm");
  const $submitButton = $form.find("button[type='submit']");
  $submitButton.attr("disabled", $form.find(".is-invalid").length > 0);
  $("[role='tabpanel']", $form).each((i, node) => {
    const $node = $(node);
    const tabPanelId = $node.attr("id");
    const $tabHeader = $(`a[href='#${tabPanelId}'][role='tab']`);
    if ($node.find(".is-invalid").length === 0) {
      $tabHeader.removeClass("text-danger");
      $tabHeader.find("i").remove();
    } else if ($tabHeader.find(".fa-exclamation-circle").length === 0) {
      $tabHeader.addClass("text-danger");
      $tabHeader.prepend('<i class="fas fa-exclamation-circle fa-xs mr-1"></i>');
    }
  });
}

function validateUrlField() {
  const $this = $(this);
  const value = $this.val();
  const required = $this.attr("required") !== undefined;
  //TODO can't we just check if this an actually valid url, we have a lot of support for URL parsing in JS
  const valid = required ? value && value.startsWith("https://") : !value || value.startsWith("https://");
  $this.parent().find(".invalid-feedback").remove();
  if (!valid) {
    $this.addClass("is-invalid");
    $this.after(`<div class="invalid-feedback">${i18next.t("asset-tracking.tabs.url-field.error")}</div>`);
  } else {
    $this.removeClass("is-invalid");
  }
  evaluateAssetTrackingForm();
}

function showAssetTrackingModal(e) {
  const $this = $(this);
  const $parent = $this.parent();
  const assetId = $this.attr("data-asset-id");
  const dataPropertyId = $parent.attr("data-em-property-id");
  const blade = $(".asset-details-blade-wrapper").get(0);
  const campaignAssetId = blade != null ? blade.getAttribute("data-campaign-asset-id") : null;
  const paramSuffix = campaignAssetId != null ? "&campaignAssetId=" + campaignAssetId : "";
  const entityPrefix = dataPropertyId.substring(0, dataPropertyId.lastIndexOf("/"));
  fetch("/manager/asset-tracking-modal?assetId=" + assetId + paramSuffix)
    .then((data) => data.text())
    .then((data) => {
      $("#modal .modal-dialog").addClass("modal-xl modal-tracking-detail");
      $("#modal .modal-content").html(data);
      $("#modal").modal("show");

      EntityModule.initializeFormElements($("#modal"));

      //make sure the last element is always in view
      $("button[data-action=add-item]").on("click", function (e) {
        const $this = $(this);
        const $collectionActions = $this.closest(".embedded-collection-actions");
        const $collectionItems = $collectionActions.siblings(".embedded-collection-items");
        const $lastItem = $collectionItems.find(".embedded-collection-item-data").last();
        $lastItem.get(0).scrollIntoView({ behavior: "smooth", block: "end" });
      });
      const $assetTrackingForm = $("#assetTrackingForm");
      $assetTrackingForm.on("submit", function (e) {
        e.preventDefault();
        e.stopPropagation();
        const $this = $(this);
        const formData = new FormData(document.querySelector("#assetTrackingForm"));
        fetch($this.attr("action"), {
          method: "post",
          headers: { "X-XSRF-Token": getCookie("XSRF-TOKEN") },
          body: formData,
        })
          .then(convertResponseToJson)
          .then((data) => {
            if (data.success) {
              $("#modal").modal("hide");
              $.each(data.properties, function (propertyName, propertyData) {
                updatePropertyData(entityPrefix + "/" + propertyName, propertyData);
              });
            } else {
              const errors = data.errors.trackingData;
              const assetTrackingFormErrors = $("#assetTrackingFormErrors");
              assetTrackingFormErrors.empty();
              //only display the first error
              if (errors.length > 0) {
                assetTrackingFormErrors.append(
                  '<i class="fas fa-exclamation-triangle mr-2"></i>' + i18next.t(errors[0].code),
                );
              }
            }
          })
          .catch(handleError);
      });
      initializeUrlFields($assetTrackingForm);
      $("[data-action='add-item']", $assetTrackingForm).on("click", () => {
        $(".url-field:last", $assetTrackingForm).focus();
        initializeUrlFields($assetTrackingForm);
      });

      $("[data-toggle='tab']", $assetTrackingForm).on("shown.bs.tab", () => {
        // eslint-disable-next-line no-undef
        autosize.update($("#assetTrackingForm textarea"));
      });
    });
}
