EntityModule.registerInitializer(function (node) {
  const FloatLabel = (() => {
    const handleFocus = (e) => {
      e.target.closest(".form-group", node).classList.add("focus");
    };

    const handleChange = (e) => {
      const target = e.target;
      if (target.selectedIndex === 0) {
        target.closest(".form-group", node).classList.remove("active");
      } else {
        target.closest(".form-group", node).classList.add("active");
      }
    };

    // remove active class
    const handleBlur = (e) => {
      const target = e.target;
      if (!target.value) {
        target.closest(".form-group", node).classList.remove("active");
        target.closest(".form-group", node).classList.remove("focus");
      }
    };

    // disable the submit on enter
    const disableSubmit = (e) => {
      if (e.keyCode === 13) {
        $(e.currentTarget).trigger("change");
        e.preventDefault();
      }
    };

    // register events
    const bindEvents = (element, querySelector) => {
      const floatFields = element.querySelectorAll(querySelector, node);
      floatFields.forEach((field) => {
        if (querySelector === "select") {
          field.addEventListener("change", handleChange);
        } else if (querySelector === ".js-entity-query-control" || querySelector === ".datetimepicker-input") {
          field.addEventListener("keydown", disableSubmit);
          field.addEventListener("focus", handleFocus);
        } else {
          field.addEventListener("focus", handleFocus);
        }
        field.addEventListener("blur", handleBlur);
      });
    };

    // get DOM elements
    const init = () => {
      const floatContainers = document.querySelectorAll(
        ".entity-query-filter-form-basic > .form-group, .filter-blade-filter-form-basic > .form-group",
      );
      floatContainers.forEach((element) => {
        if (!!element.querySelector(".multi-value-autosuggest", node)) {
          if (element.querySelector(".tt-input", node).value) {
            element.classList.add("active");
          }
          bindEvents(element, ".tt-input");
        } else if (!!element.querySelector(".bootstrap-select", node)) {
          if (
            element.querySelector("select").querySelector("option[selected=selected]") !== null &&
            !!element.querySelector("select").querySelector("option[selected=selected]").value
          ) {
            element.classList.add("active");
          }
          bindEvents(element, "select");
        } else if (!!element.querySelector(".js-entity-query-control", node)) {
          if (element.querySelector(".js-entity-query-control", node).value) {
            element.classList.add("active");
          }
          bindEvents(element, ".js-entity-query-control");
        } else if (!!element.querySelector(".datetimepicker-input")) {
          if (element.querySelector(".datetimepicker-input", node).value) {
            element.classList.add("active");
          }
          bindEvents(element, ".datetimepicker-input");
        }
      });
    };

    return {
      init: init,
    };
  })();

  FloatLabel.init();
});
