function additionalTypesDropdown(node) {
  var suffix = $("[options-type ='channel']").find($("[checked ='checked']")).parent().attr("amount-suffix");
  if (!!suffix) {
    $("form").find(".input-group-addon").html(suffix);
  }

  $("[value ='additionalTypesDropdown']", node).on("click", function (event) {
    var typeId = $(event.target).attr("data-value");
    if (!!typeId) {
      var currentUrl = window.location.href.split("?")[0];
      // this is the button, its parent is the partial view element, which we are going to replace
      var destination = $(this).parent().parent();
      console.log("refreshing additional types", this, destination);
      var selectionData = $(this).closest("form").serialize();

      $.ajax({
        type: "post",
        url: currentUrl,
        data: "property=" + typeId + "&_partial=::ProductFeatureValueSelectorForCollectionControl&" + selectionData,
      }).done(function (data) {
        $(destination).html(data);
        EntityModule.initializeFormElements($(destination));
      });
    }
  });
}

EntityModule.registerInitializer(additionalTypesDropdown);
