import WaveSurfer from "wavesurfer.js";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor";

function formatTimeCallback(seconds, pxPerSec) {
  seconds = Number(seconds);
  var minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  var secondsStr = Math.round(seconds).toString();
  secondsStr = seconds.toFixed(0);

  if (minutes > 0) {
    if (seconds < 10) {
      secondsStr = "0" + secondsStr;
    }
    return `${minutes}:${secondsStr}`;
  }
  return secondsStr;
}

function timeInterval(pxPerSec) {
  return 1;
}

function primaryLabelInterval(pxPerSec) {
  return 1;
}

function secondaryLabelInterval(pxPerSec) {
  return 0;
}

export function initWaveSurfer(node, duration, container, timelineContainer, height, audioContext) {
  const wavesurfer = WaveSurfer.create({
    container: container,
    waveColor: "#007bff",
    progressColor: "#0056b3",
    backend: "MediaElement",
    splitChannels: true,
    cursorColor: "#c010c0",
    height: height,
    audioContext: audioContext,
    splitChannelsOptions: {
      overlay: false,
      relativeNormalization: true,
      filterChannels: [],
      channelColors: {
        0: { progressColor: "#0056b3", waveColor: "#007bff" },
        1: { progressColor: "#0056b3", waveColor: "#007bff" },
      },
    },
    plugins: [
      TimelinePlugin.create({
        formatTimeCallback: formatTimeCallback,
        timeInterval: timeInterval,
        primaryLabelInterval: primaryLabelInterval,
        secondaryLabelInterval: secondaryLabelInterval,
        container: timelineContainer,
      }),
      CursorPlugin.create({
        showTime: true,
        opacity: 1,
        customShowTimeStyle: {
          "background-color": "#c010c0",
          color: "#fff",
          padding: "2px",
          "font-size": "15px",
        },
      }),
    ],
  });
  $(node).prop("wavesurferInstance", wavesurfer);
  const source = $(".wave", node).attr("data-src");
  wavesurfer.on("waveform-ready", function () {
    $(wavesurfer.params.container).prev().addClass("d-none");
  });
  wavesurfer.load(source);
  const play = $(".play", node);
  const pause = $(".pause", node);
  $(".wave-play-button", node).on("click", wavesurfer.playPause.bind(wavesurfer));
  $(".wave-play-button", node).on("click", () => {
    if (play.hasClass("show")) {
      pause.addClass("show");
      play.removeClass("show");
    } else {
      play.addClass("show");
      pause.removeClass("show");
    }
  });
  wavesurfer.on("finish", function () {
    play.addClass("show");
    pause.removeClass("show");
  });
  return wavesurfer;
}
