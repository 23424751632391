import { convertResponseToText, getCookie, handleError } from "./utils";

/**
 * Activates support for data-partial-load.
 */

function addParametersToUrl(url) {
  const parametersToCopyToUrl = ["personal"];
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.forEach(function (value, key) {
    if (parametersToCopyToUrl.includes(key)) {
      url += "&" + key;
      url += !!value ? +"=" + value : "";
    }
  });
  return url;
}

EntityModule.registerInitializer(function (node) {
  $("[data-partial]", node).each(function (idx, value) {
    var _this = $(this);
    $.get(_this.data("partial"), function (data) {
      var wrapper = _this.find(".js-data-wrapper");
      wrapper.html(data);
      EntityModule.initializeFormElements(wrapper);
    });
  });

  // automatically load partial content when a control is modified
  function buildPartialLoadParameters(element, config) {
    var parameters = $.merge(config, { data: "" });
    if (parameters.form == null && parameters.url == null) {
      // neither url nor form specified, default to fetching any current form
      parameters.form = "form";
    }

    if (parameters.form != null) {
      var formToSubmit = element.closest(parameters.form);
      if (formToSubmit.length) {
        parameters.data = formToSubmit.serialize();

        if (parameters.url == null) {
          parameters.url = formToSubmit.attr("action");
        }
      }
    }

    if (parameters.url == null) {
      parameters.url = window.location.href;
    }
    return parameters;
  }

  function configurePartialRefresh(element, config) {
    $(element).one(
      "bootstrapui.change",
      _.debounce(function () {
        var parameters = buildPartialLoadParameters($(this), config);
        var control = $(this);

        var target;

        if (parameters.target == null) {
          // no target specified, check if another container is labelled as target for the partial
          target = $('[data-partial-target="' + parameters.partial + '"]');

          if (!target.length) {
            // none found, replace the current element
            target = control;
          }
        } else {
          target = $(parameters.target);
        }

        // if the target does not have the 'data-partial-target' - add a wrapper
        var wrapperAdded = false;
        if (!target.data("partial-target")) {
          wrapperAdded = true;
          target.wrap('<div data-partial-target="' + parameters.partial + '"></div>');
          target = $('[data-partial-target="' + parameters.partial + '"]');
        }
        target.addClass("partial-loading");
        target.append('<div class="partial-spinner"></div>');
        var url =
          parameters.url.substring(0, parameters.url.indexOf("?")) +
          "?" +
          parameters.data +
          "&" +
          $.param({ _partial: "::" + parameters.partial });
        url = addParametersToUrl(url);
        fetch(url, {
          method: parameters.method,
          redirect: "follow",
          headers: {
            "X-XSRF-Token": getCookie("XSRF-TOKEN"),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
          .then(convertResponseToText)
          .then((data) => {
            target.html(data);
            EntityModule.initializeFormElements(target);
            target.removeClass("partial-loading");
            var newUrl = url.split("?")[0] + "?" + parameters.data;
            newUrl = addParametersToUrl(newUrl);
            window.history.pushState({ path: newUrl }, "", newUrl);
            if (wrapperAdded) {
              var firstChild = target.children().first();
              if (firstChild.length) {
                firstChild.unwrap();
              }
            }
          })
          .catch(handleError);
      }, 1),
    );
  }

  $("[data-partial-load]", node).each(function () {
    var config = $(this).data("partial-load");

    configurePartialRefresh(this, config);
  });

  $("[data-multi-partial-load]", node).each(function () {
    var _element = $(this);
    var config = $(this).data("multi-partial-load");
    if (config && config.partials) {
      var partials = config.partials;
      if (partials && partials.length) {
        for (let i = 0; i < partials.length; i++) {
          configurePartialRefresh(_element, partials[i]);
        }
      }
    }
  });
});
