import { ax, convertResponseToText, getCookie } from "./utils";
import { updatePropertyData } from "./editable-value";
import { hideSpinner, showSpinner } from "./spinner";
import { apm } from "../apm";
import { showErrorModal, showValidationErrorModal } from "./errormodal";

EntityModule.registerInitializer(function (node) {
  initializeModalConfigurations(node);
});

export function initializeModalConfigurations(node) {
  $("[data-modal-load]", node).each((idx, element) => {
    initializeModalConfiguration(element);
  });
}

function initializeModalConfiguration(element) {
  let $node = $(element);
  let config = $.extend(true, {}, $node.data("modal-load"));

  const targetModalId = "#" + config.targetModalId;
  $node.off().on("click", (e) => {
    e.preventDefault();
    $(targetModalId).one("show.bs.modal", (event) => {
      executeFetchRequest(config.content.url, config.content.method)
        .then(convertResponseToText)
        .then((html) => {
          if (targetModalId) {
            replaceModalContent(targetModalId, html);
            $(targetModalId).trigger("modal:content-loaded");
          }
        })
        .catch((e) => {
          $(targetModalId).modal("hide");
          if (e !== null) {
            apm.captureError(e);
            console.error(e);
          }
          showErrorModal("<p>Oops! Something went wrong, try again later</p>");
        });
    });

    config.submissions.forEach(function (item, index) {
      configureModalSubmission(targetModalId, config, item, item.name);
    });

    $(targetModalId).on("hide.bs.modal", (e) => {
      cleanUpModal(targetModalId);
    });
  });
}

export function replaceModalContent(target, content) {
  $(target + " .modal-body").html("</form>" + content);
  EntityModule.initializeFormElements($(target + " .modal-body"));
}

function configureModalSubmission(target, config, submissionConfig, btnId) {
  let $submissionBtn = $(target + " .js-" + btnId);
  $submissionBtn.prop("disabled", false);

  $submissionBtn.off().on("click", (e) => {
    showSpinner("#" + btnId);
    e.preventDefault();
    e.stopPropagation();

    $(this).prop("disabled", true);
    $(this).find(".loading").removeClass("d-none");
    const requestConfiguration = $.extend(
      {},
      {
        type: submissionConfig.type,
        url: submissionConfig.url,
        method: submissionConfig.method,
        form: `${target} form`,
      },
    );
    executeRequest(requestConfiguration)
      .then((response) => {
        hideSpinner("#" + btnId);
        if (response.redirected) {
          if (!config.ignoreRedirects) {
            window.location.href = response.url;
          }
          return Promise.resolve({ replace: false });
        } else if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (contentType.includes("application/json")) {
            return response.json();
          } else {
            return response.text().then((html) => {
              return {
                content: html,
                replace: true,
              };
            });
          }
        } else {
          if (apm) {
            apm.captureError("Error while calling " + submissionConfig.url);
          }
          console.error("Error while calling " + submissionConfig.url);
          if (response.status === 601) {
            response.text().then(showValidationErrorModal);
          } else {
            showErrorModal("<p>Oops! Something went wrong, try again later</p>");
          }
        }
      })
      .then((data) => {
        if (data.success) {
          $(target).modal("hide");
          $.each(data.properties, function (propertyName, propertyData) {
            updatePropertyData(propertyName, propertyData);
          });
        } else {
          if (data && data.replace) {
            replaceModalContent(target, data.content);
          } else {
            $(target).modal("hide");
            if (config.refreshOnClose && config.refreshOnClose) {
              handleRefreshOnClose(config.refreshOnClose);
            }
          }
        }
        $(target).trigger("modal:action-complete", data);
      });
  });
}

/*
    todo.stg: create a global javascript object which holds functions we can execute.
    This way we can reuse functionality across pages/components
 */
function handleRefreshOnClose(config) {
  // todo.stg can we extract the unpoly specifics in h.then logic?
  const executed = executeRequest(config);
  if (!config.type.startsWith("unpoly")) {
    if (Array.isArray(executed)) {
      executed.forEach((data) => {
        if (!data.partial.type.startsWith("unpoly")) {
          refreshPartial(data.promise);
        }
      });
    } else {
      refreshPartial(executed);
    }
  }
}

function cleanUpModal(target) {
  $(target + " .modal-body").html("");
  let $submissionBtn = $(target + " .js-modal-submit");
  $submissionBtn.prop("disabled", false);
  $submissionBtn.find(".loading").addClass("d-none");
}

function executeRequest(requestConfig) {
  switch (requestConfig.type) {
    case "partial:multi":
      return requestConfig.partials.map((partial) => {
        return {
          partial: partial,
          promise: executeRequest(partial),
        };
      });
    case "partial:single":
      return executePartialRequest(requestConfig);
    case "unpoly:reload":
      return UNPOLY_REQUEST.reload(requestConfig);
    case "unpoly:replace":
      return UNPOLY_REQUEST.replace(requestConfig);
    default:
      ax.log.debug("Did not find a suitable request handler. Config: ", requestConfig);
  }
}

const UNPOLY_REQUEST = {
  reload: function (req) {
    return Promise.resolve(req.url ? up.reload(req.target, req.url) : up.reload(req.target));
  },
  replace: function (req) {
    ax.log.debug("unpoly replace requests have not been implemented yet");
    return Promise.resolve();
  },
};

function executePartialRequest(partialConfiguration) {
  const { partial, form, method, url } = partialConfiguration;
  const baseUrl = url ? url : window.location.href.split("?")[0];
  const requestUrl = partial
    ? url.indexOf("?") === -1
      ? `${baseUrl}?_partial=${partial}`
      : `${baseUrl}&_partial=${partial}`
    : baseUrl;
  const formToSerialize = form ? $(form) : null;
  if (formToSerialize) {
    return executeFormRequest(requestUrl, method, formToSerialize);
  }
  return executeFetchRequest(requestUrl, method, formToSerialize);
}

function executeFormRequest(url, method, form) {
  let formConfiguration = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  if (form.attr("enctype") === "multipart/form-data") {
    formConfiguration.body = new FormData(form[0]);
    formConfiguration.processData = false;
    formConfiguration.contentType = false;
    formConfiguration.cache = false;
  } else {
    formConfiguration.body = getFormDate($(form[0]));
  }

  return executeFetchRequest(url, method, formConfiguration);
}

export function executeFetchRequest(url, method, requestConfiguration) {
  const fetchConfiguration = $.extend(
    true,
    {},
    {
      method: method,
      headers: {
        "X-XSRF-Token": getCookie("XSRF-TOKEN"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    requestConfiguration,
  );
  return fetch(url, fetchConfiguration);
}

function getFormDate($form) {
  const data = new URLSearchParams();
  var form = $form.serializeArray();
  if (form.length) {
    for (var i = 0; i < form.length; i++) {
      var key = form[i].name,
        value = form[i].value;
      if (key.substr(key.length - 2, 2) === "[]") {
        key = key.substr(0, key.length - 2);
      }

      if (data.get(key) != null) {
        let valueToAppend = data.get(key);
        if (valueToAppend.push !== undefined) {
          valueToAppend.push(value);
        } else {
          valueToAppend = [valueToAppend, value];
        }
        data.set(key, valueToAppend);
      } else {
        data.append(key, value);
      }
    }

    return data;
  }
}

/*
    todo.stg: create a global javascript object which holds functions we can execute.
    This way we can reuse functionality across pages/components
 */
function refreshPartial(partialConfiguration) {
  const { partial, form, method, url, target } = partialConfiguration;
  const baseUrl = url ? url : window.location.href.split("?")[0];
  const requestUrl = `${baseUrl}?_partial=${partial}`;
  const formToSerialize = form ? $(form) : null;
  executeRequest(requestUrl, method, formToSerialize)
    .then(convertResponseToText)
    .then((html) => {
      $(target).replaceWith(html);
      EntityModule.initializeFormElements($(target));
    });
}
