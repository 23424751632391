EntityModule.registerInitializer(function (node) {
  hideAllActionFieldGroups(node);
  $("div[data-em-property='possibleTransitionActions'] .checkbox-list", node).each(function () {
    const $possibleTransactionsSelector = $(this);
    showCorrectActionFieldGroups($possibleTransactionsSelector);
    $possibleTransactionsSelector.change(function () {
      hideAllActionFieldGroups(node);
      showCorrectActionFieldGroups($possibleTransactionsSelector);
    });
  });
});

function hideAllActionFieldGroups(node) {
  $("div[data-em-property='possibleTransitionActions'] .action-field-group", node).hide();
}

function showCorrectActionFieldGroups($possibleTransactionsSelector) {
  $possibleTransactionsSelector.find(":checked").each(function () {
    var selectedAction = $(this).val();
    $(`div[data-em-property='possibleTransitionActions'] [data-action-id='${selectedAction}']`).show();
  });
}
