import { convertResponseToTextWithoutSettingRedirectUrl, getCookie, handleError } from "./utils";
import { showErrorModal } from "./errormodal";
import { showSpinner } from "./spinner";

$("#tc-modal-accept-button").prop("disabled", true);

$("#tc-modal-accept-terms").on("change", function (event) {
  if ($(this).prop("checked")) {
    $("#tc-modal-accept-button").prop("disabled", false);
  } else {
    $("#tc-modal-accept-button").prop("disabled", true);
  }
});

$(document).on("click", "#tc-modal-logout-button", function (event) {
  window.location.href = "/logout";
});

$(document).on("click", "#tc-modal-accept-button", function (event) {
  showSpinner("#tc-modal-accept-button");
  const params = {
    type: $("#documentType").val(),
    date: $("#documentDate").val(),
  };

  fetch("/manager/accept-policy?", {
    method: "POST",
    headers: {
      "X-XSRF-Token": getCookie("XSRF-TOKEN"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .then(convertResponseToTextWithoutSettingRedirectUrl)
    .catch(handleError);
});

$(document).on("click", "#tc-modal-decline-button", function (event) {
  $(".js-accept-conditions-modal").addClass("d-none");

  $(".js-accept-conditions-modal").parent().find(".modal-backdrop").remove();

  const result = document.querySelector("#decline-conditions-template").content.cloneNode(true);
  $("#errorModal .js-error-modal-title").css("display", "none");
  $("#errorModal .js-error-modal-button").css("display", "none");
  showErrorModal(result);
  $("#errorModal").css("display", "inline-block");
});

$(document).on("click", "#tc-modal-back-button", function (event) {
  $("#errorModal").css("display", "none");

  $(".js-accept-conditions-modal").removeClass("d-none");
});
