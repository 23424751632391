import i18next from "../i18n";
import { convertResponseToJson, getCookie, handleError } from "./utils";

export function initializeTagsInput($tagInput, emptyTemplate, tagType) {
  const bloodhoundTagAutocomplete = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
    queryTokenizer: Bloodhound.tokenizers.obj.whitespace("value"),
    remote: {
      url: "/api/tag?prefix=%QUERY&type=" + tagType,
      wildcard: "%QUERY",
    },
  });
  bloodhoundTagAutocomplete.initialize();
  window.bloodhoundTagAutocomplete = bloodhoundTagAutocomplete;

  var suggestionTemplate = `<div class="suggestion">{{name}}</div>`;
  var $dummyPlusTag = $('<span class="tag label label-info">+</span>');
  $tagInput.tagsinput({
    itemValue: "tagId",
    itemText: "name",
    delimiter: "|",
    maxChars: 30,
    typeaheadjs: [
      {
        hint: true,
        highlight: true,
        minLength: 1,
        maxLength: 30,
      },
      {
        name: "tags",
        displayKey: "name",
        source: bloodhoundTagAutocomplete.ttAdapter(),
        templates: {
          empty: Handlebars.compile(emptyTemplate),
          suggestion: Handlebars.compile(suggestionTemplate),
        },
      },
    ],
    freeInput: false,
  });
  var $tagsInput = $(".bootstrap-tagsinput");
  var $inputfield = $(".bootstrap-tagsinput .tt-input", $tagInput.parent());
  $(".bootstrap-tagsinput .tt-input", $tagInput.parent()).attr("maxLength", 30);
  $tagInput.on("itemAddedOnInit", function (event) {
    $dummyPlusTag.remove($tagsInput);
    $dummyPlusTag.insertBefore($inputfield);
  });
  $tagInput.on("itemAdded", function (event) {
    $dummyPlusTag.remove($tagsInput);
    $dummyPlusTag.insertBefore($inputfield);
  });
  if ($tagInput.attr("tags") === undefined || $tagInput.attr("tags") === "[]") {
    $dummyPlusTag.insertBefore($inputfield);
  }
  $inputfield.focusin(function () {
    $dummyPlusTag.remove();
  });
  $inputfield.focusout(function () {
    $dummyPlusTag.insertBefore($inputfield);
  });
  $(".twitter-typeahead").on("keyup", function (e) {
    //remove single quotes from input
    if (e.which === 52) {
      $(this).find(".tt-input").val($(this).find(".tt-input").val().replace(/'/g, ""));
      $(this).find(".tt-input").trigger("input");
    }
    if (e.which === 13) {
      $(".tt-suggestion:first-child", this).trigger("click");
    }
  });
}

function addTag(newTag, $tagInput, tagType) {
  fetch("/api/tag", {
    method: "post",
    headers: {
      "X-XSRF-Token": getCookie("XSRF-TOKEN"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name: newTag, type: tagType }),
  })
    .then(convertResponseToJson)
    .then((data) => {
      if (window.bloodhoundTagAutocomplete) {
        window.bloodhoundTagAutocomplete.clear();
        window.bloodhoundTagAutocomplete.clearRemoteCache();
      }
      $tagInput.parent().find(".tt-input").val("");
      $tagInput.parent().find(".tt-input").trigger("input");
      $tagInput.tagsinput("add", { tagId: data.tagId, name: data.name });
    })
    .catch(handleError);
}

window.addTag = addTag;

function removeQuery($tagInput) {
  $tagInput.parent().find(".tt-input").val("");
  $tagInput.parent().find(".tt-input").trigger("input");
}

window.removeQuery = removeQuery;

/**
 * Supports initializing a bootstrap tagsinput field with prefilled tags.
 * To initialize the tags input field, an {@literal add-tag-template} element should be present,
 * which can be used as the template for adding new tags.
 *
 * @param selector selector for the input field holding the values to be submitted.
 * @param tagType type of the tag.
 * @param selectorParentNode optional parent node in which should be looked for the selector.
 */
export function initializeCustomTagAutosuggests(selector, tagType, selectorParentNode) {
  const addTagText = i18next
    .t("Frontend.tags.add-tag", {
      0: "%query%",
    })
    .replace("%query%", "{{query}}");
  const emptyTemplate = document.getElementById("add-tag-template").innerHTML;

  const emptyTemplateFn = Handlebars.compile(emptyTemplate);
  const emptyTemplateContent = emptyTemplateFn({
    tagBody: "{{query}}",
    addTagText: addTagText,
    selector: selector,
    tagType: tagType,
  });

  initializeTagsInput($(selector, selectorParentNode), emptyTemplateContent, tagType);

  $(selector).each(function () {
    var $input = $(this);
    let currentTags = $input.attr("tags");
    if (currentTags) {
      var tags = JSON.parse(currentTags);
      if (tags.length) {
        tags.forEach(function (tag) {
          $input.tagsinput("add", { tagId: tag.id, name: tag.description }, { preventPost: true });
        });
      }
    }
  });
}
