import { updatePropertyData } from "./editable-value";
import { handleError } from "./utils";
import { executeFetchRequest } from "./modal-loader";

EntityModule.registerInitializer(function (node) {
  $("[data-request]", node).each((idx, element) => {
    initializeRequestConfiguration(element);
  });
});

function initializeRequestConfiguration(element) {
  let $node = $(element);
  let config = $.extend(true, {}, $node.data("request"));

  $node.on("click", (e) => {
    e.preventDefault();
    executeFetchRequest(config.url, config.method)
      .then((response) => {
        if (response.redirected) {
          window.location.href = response.url + "&redirectUrl=" + encodeURI(window.location.href);
          return Promise.reject(null);
        } else {
          const contentType = response.headers.get("content-type");
          if (contentType.includes("application/json")) {
            return response.json();
          } else {
            return response.text().then((html) => {
              return {
                content: html,
                replace: true,
              };
            });
          }
        }
      })
      .then((data) => {
        if (data.success) {
          $.each(data.properties, function (propertyName, propertyData) {
            updatePropertyData(propertyName, propertyData);
          });
          $node.trigger("request:action-complete", data);
        }
      })
      .catch(handleError);
  });
}
