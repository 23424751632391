export function showErrorModal(content, showErrorImage) {
  if (!showErrorImage) {
    $("#errorModal .modal-image-wrapper").css("display", "none");
  }
  $("#errorModal .modal-body-content").html(content);
  $("#errorModal").modal("show");
}

export function showValidationErrorModal(content, showErrorImage) {
  if (!showErrorImage) {
    $("#validationErrorModal .modal-image-wrapper").css("display", "none");
  }
  $("#validationErrorModal .modal-body-content").html(content);
  $("#validationErrorModal").modal("show");
}
