import { init as initApm } from "@elastic/apm-rum";
import { getCookie } from "./components/utils";

let apmInstance = null;

if (window.camalion.rumServiceName && window.camalion.rumServerUrl && window.camalion.rumEnvironment) {
  apmInstance = initApm({
    serviceName: window.camalion.rumServiceName,
    serverUrl: window.camalion.rumServerUrl,
    environment: window.camalion.rumEnvironment,
    pageLoadTransactionName: window.location.pathname,
  });
}

export const apm = apmInstance;
let camid = getCookie("camid");
if (typeof camid === "undefined") {
  camid = "unknown";
}
if (apm !== null) {
  apm.addLabels({ cama_uniqueid: camid });
}
