/**
 * Sets up default blade view opening and behaviour.
 */

/**
 * Calculate if the blade container must be sticky
 */
var blade = document.getElementById("blade-container");
var sticky = blade.offsetTop;
window.onscroll = function () {
  if (window.pageYOffset > sticky) {
    blade.classList.add("sticky");
  } else {
    blade.classList.remove("sticky");
  }
};

/**
 * Open the blade as soon as .blade-content is inserted.
 */
up.compiler(".blade-content", function () {
  setTimeout(function () {
    // use timeout to active the animation
    $(".page-wrapper").addClass("show-blade");

    // initialize close button
    $(".blade-header", $(blade))
      .find("button.actions-close")
      .on("click", function (event) {
        var pageWrapper = $(".page-wrapper");
        if (pageWrapper.hasClass("show-blade")) {
          pageWrapper.removeClass("show-blade");
        }
      });
  });
});

/**
 * Hide the blade when the user clicks outside of it.
 * Uses mousedown as blade might be opened with 'up-instant', and else the
 * 'click' would follow the mouse down and close instantly.
 */
$(document).on("mousedown", function (event) {
  var target = $(event.target);
  var pageWrapper = $(".page-wrapper");
  if (
    !target.closest(".blade-wrapper").length &&
    !target.closest(".up-modal").length &&
    pageWrapper.hasClass("show-blade")
  ) {
    pageWrapper.removeClass("show-blade");
  }
});

/**
 * Sortable table summary urls always open inside the blade.
 */
up.macro("tr[data-summary-url]", function (row) {
  row.setAttribute("up-target", ".blade-wrapper");
  row.setAttribute("up-href", row.getAttribute("data-summary-url"));
  row.setAttribute("up-restore-scroll", true);
  row.setAttribute("up-history", false);
  row.setAttribute("up-instant", "up-instant"); // load on mousedown
  row.setAttribute("up-cache", false);
});
