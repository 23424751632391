import { apm } from "../apm";
import { showErrorModal } from "./errormodal";

export function convertResponseToText(response) {
  if (response.redirected) {
    window.location.href = response.url + "&redirectUrl=" + encodeURI(window.location.href);
    return Promise.reject(null);
  } else if (response.ok) {
    return response.text();
  } else {
    return Promise.reject({ status: response.status, statusText: response.statusText });
  }
}

export function convertResponseToTextWithErrorFunction(response, errorFunction) {
  if (response.redirected) {
    window.location.href = response.url + "&redirectUrl=" + encodeURI(window.location.href);
    return Promise.reject(null);
  } else if (response.ok) {
    return response.text();
  } else {
    errorFunction(response);
    return Promise.reject(null);
  }
}

export function convertResponseToTextWithoutSettingRedirectUrl(response) {
  if (response.redirected) {
    window.location.href = response.url;
    return Promise.reject(null);
  } else {
    return response.text();
  }
}

export function convertResponseToJson(response) {
  if (response.redirected) {
    window.location.href = response.url + "&redirectUrl=" + encodeURI(window.location.href);
  } else {
    return response.json();
  }
}

export function handleError(e) {
  if (e !== null) {
    if (apm) {
      apm.captureError(e);
    }
    console.error(e);
    showErrorModal("<p>Oops! Something went wrong, try again later</p>");
  }
}

export function handleErrorWithMessage(data, handleErrorFunction) {
  data.text().then((text) => {
    if (!!text) {
      apm.captureError(text);
      console.error(text);
      showErrorModal(`<p>${text}</p>`);
      handleErrorFunction();
    } else {
      showErrorModal("<p>Oops! Something went wrong, try again later</p>");
    }
  });
}

export function renderTemplate(id, destination, templateConfigurationFunction) {
  var result = document.querySelector(id).content.cloneNode(true);
  templateConfigurationFunction(result);
  $(destination).prepend(result);
}

export function renderTemplateAndReplace(id, destination, templateConfigurationFunction) {
  var result = document.querySelector(id).content.cloneNode(true);
  templateConfigurationFunction(result);
  $(destination).html(result);
}

export function getFragments() {
  var str = window.location.hash;
  var objURL = {};

  str.replace(new RegExp("([^#=&]+)(=([^&]*))?", "g"), function ($0, $1, $2, $3) {
    objURL[$1] = $3;
  });
  return objURL;
}

export function getQueryParams() {
  var str = window.location.search;
  var objURL = {};

  str.replace(new RegExp("([^?=&]+)(=([^&]*))?", "g"), function ($0, $1, $2, $3) {
    objURL[$1] = $3;
  });
  return objURL;
}

export function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
}

export function getBrowser() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") !== -1) {
    if (ua.indexOf("chrome") > -1) {
      return "CHROME";
    } else {
      return "SAFARI";
    }
  }
}

export const ax = {
  log: {
    sessionStorageKey: "ax.log.enabled",
    enabled: false,
    prefix: "[AX] ",

    enable() {
      this.enabled = true;
      window.sessionStorage && window.sessionStorage.setItem(this.sessionStorageKey, "true");
    },
    disable() {
      this.enabled = false;
      window.sessionStorage && window.sessionStorage.removeItem(this.sessionStorageKey);
    },
    info(message, params) {
      this.send(console.info, message, params);
    },
    debug(message, params) {
      this.send(console.debug, message, params);
    },
    error(message, params) {
      this.send(console.error, message, params);
    },
    group(title, params) {
      this.send(console.group, title, params);
    },
    groupCollapsed(title, params) {
      this.send(console.groupCollapsed, title, params);
    },
    groupEnd() {
      this.send(console.groupEnd);
    },
    send(target, message, params) {
      if (this.enabled) {
        target(this.prefix + message, params ? params : "");
      }
    },
  },
};

if (window.sessionStorage && sessionStorage.getItem(ax.log.sessionStorageKey) === "true") {
  ax.log.enable();
}

/**
 * This function tries to avoid undefined tokens in the header when browsers don't allow the cookie to be set
 */
export function getTokenSafeHeaders() {
  let cookie = getCookie("XSRF-TOKEN");
  if (!cookie) {
    return { "Content-Type": "application/x-www-form-urlencoded" };
  }
  return {
    "X-XSRF-Token": cookie,
    "Content-Type": "application/x-www-form-urlencoded",
  };
}
