EntityModule.registerInitializer(function () {
  $(".js-price-modifier-select a").on("click", function (event) {
    dropDownItemClicked(event);
  });

  $(".js-price-modifier-input").on("blur", function (event) {
    setPriceModifier(event.currentTarget.value);
  });
});

function dropDownItemClicked(event) {
  updateCurrencySymbol(event);
  setHelpText($(event.currentTarget));
  setPriceModifier($(".js-price-modifier-input").val());
}

function setPriceModifier(modifierNumber) {
  modifierNumber = modifierNumber.replace(".", "").replace(",", ".").replace("€", "").trim();

  $(".js-price-modifier").val(modifierNumber);
}

function updateCurrencySymbol(event) {
  const currencySymbol = " " + event.currentTarget.text;
  $(".js-price-modifier-input[data-bootstrapui-numeric]")
    .data("autoNumeric")
    .update({ currencySymbol: currencySymbol });
  $(".js-price-modifier-type-value").text(currencySymbol);
}

function setHelpText(selectedType) {
  $(".js-price-modifier-help").text(selectedType.data("help"));
}
