import "../globals";
import {
  convertResponseToText,
  convertResponseToTextWithoutSettingRedirectUrl,
  getCookie,
  getTokenSafeHeaders,
  handleError,
} from "../components/utils";
import { hideSpinner, showSpinner, showSpinnerCenter } from "../components/spinner";

EntityModule.registerInitializer(function (node) {
  $("#loginForm")
    .removeAttr("disabled")
    .off()
    .submit(function () {
      showSpinnerCenter("#loginNext");
      showSpinnerCenter("#login");
    });
  $("#emailSubmission")
    .off()
    .click(function (e) {
      e.preventDefault();
      const emailDetails = $(this).closest("form").serialize();
      register(emailDetails);
    });
  $(".TogglePassword").click(function () {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $("#password");
    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });
});

function register(emailDetails) {
  postAndRender(`/register?state=EMAIL_REQUIRED`, emailDetails, "#registrationLandingModal");
}

function postAndRender(url, body, selector, callback = undefined) {
  fetch(url, {
    method: "post",
    redirect: "follow",
    headers: getTokenSafeHeaders(),
    body: body,
  })
    .then(convertResponseToTextWithoutSettingRedirectUrl)
    .then((html) => {
      const requestedContent = $(html).find(selector).html();
      $(selector).html(requestedContent);
      if (typeof callback !== "undefined") {
        callback();
      }
      EntityModule.initializeFormElements($(selector));
    })
    .catch(handleError);
}

function attachRegistrationFormSubmit() {
  $("#emailSubmission")
    .off()
    .click(function (e) {
      e.preventDefault();
      const emailDetails = $(this).closest("form").serialize();
      register(emailDetails);
    });
}

function register(emailDetails) {
  showSpinner("#emailSubmission");
  fetch(`/register?state=EMAIL_REQUIRED`, {
    method: "post",
    redirect: "follow",
    headers: {
      "X-XSRF-Token": getCookie("XSRF-TOKEN"),
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept-Language": navigator.language,
    },
    body: emailDetails,
  })
    .then(convertResponseToText)
    .then((html) => {
      hideSpinner("#emailSubmission");
      const requestedContent = $(html).find("#registrationLandingModal").html();
      $("#registrationLandingModal").html(requestedContent);
      EntityModule.initializeFormElements($("#registrationLandingModal"));
      attachRegistrationFormSubmit();
    })
    .catch(handleError);
}
