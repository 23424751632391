import i18next from "../i18n";
import { showErrorModal } from "./errormodal";

export function initializeSigningButton() {
  $("#sign-button").click(function (event) {
    event.preventDefault();
    $.ajax({
      type: "POST",
      url: $("#sign-button")[0].href,
      success: function (response) {
        //We set window.location.href in order to be able to go back to the overview page of the campaign if we want
        window.location.href = decodeURI(response);
      },
      error: function (xhr) {
        console.error(xhr.responseText);
        showErrorModal(i18next.t(xhr.responseText));
      },
    });
  });
}
