// auto-stretch textboxes in inline controls
Stretchy.selectors.filter = ".editable-value-wrapper *";

// general Unpoly setup
var unpolySetupFinished = false;

// Hookup EntityModule initialization with Unpoly fragments, because fragment:inserted
// also fires on the initial Unpoly bootstrap, we need to skip the first call to
// avoid intializeFormElements from being executed twice on the same nodes
up.on("up:fragment:inserted", function (event, fragment) {
  if (unpolySetupFinished) {
    EntityModule.initializeFormElements(fragment);
  } else {
    unpolySetupFinished = true;
  }
});

// Drawer modal - can be used for notifications
up.modal.flavors.drawer = {
  openAnimation: "move-from-right",
  closeAnimation: "move-to-right",
  position: "right",
};

up.on("up:proxy:loaded", (event) => {
  const responseUrl = event.response.url;
  if (!!responseUrl && responseUrl.includes("login")) {
    event.preventDefault();
    location.href = responseUrl;
  }
});

$("body").tooltip({ selector: "[data-toggle=tooltip]" });
