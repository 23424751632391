/**
 * Adapts the default navigation to work through Unpoly.
 */
{
  /**
   * Closes custom layers that should be closed when navigation changes.
   */
  function closeLayers() {
    $(".page-wrapper").removeClass("show-blade");
  }

  function isUnpolyAware(node) {
    return (
      node.hasAttribute("up-target") ||
      node.hasAttribute("up-popup") ||
      node.hasAttribute("up-modal") ||
      node.hasAttribute("up-drawer")
    );
  }

  /**
   * Sidebar and breadcrumb navigation always updates the page-content wrapper.
   */
  up.macro(
    ".sidebar a.list-group-item-action:not(.list-group-subgroup-toggle), .breadcrumb .breadcrumb-item a",
    function (link) {
      link.setAttribute("up-target", ".page-content-wrapper");
      link.setAttribute("up-restore-scroll", true);
      link.setAttribute("up-history", true);
      link.setAttribute("up-instant", "up-instant"); // load on mousedown
      link.setAttribute("up-cache", false);

      $(link).on("mousedown", closeLayers);
    },
  );

  /**
   * In-page tab navigation only updates the tab section.
   */
  up.macro(".tabbable .pcs-nav a.nav-link", function (link) {
    link.setAttribute("up-target", ".main");
    link.setAttribute("up-restore-scroll", true);
    link.setAttribute("up-history", true);
    link.setAttribute("up-instant", "up-instant"); // load on mousedown
    link.setAttribute("up-cache", false);

    $(link).on("mousedown", closeLayers);
  });

  /**
   * List form and sortable table links refresh the main by default (if on target set).
   */
  up.macro(
    ".list-header-actions a:not(.js-disable-unpoly), .em-sortableTable-table td.row-actions a:not(.download-asset), .main #btn-cancel, tr[data-target-url]",
    function (link) {
      if (!isUnpolyAware(link)) {
        link.setAttribute("up-target", ".main");
        link.setAttribute("up-restore-scroll", false);
        link.setAttribute("up-history", true);
        link.setAttribute("up-cache", false);

        if (link.hasAttribute("data-target-url")) {
          link.setAttribute("up-href", link.getAttribute("data-target-url"));
        }

        $(link).on("mousedown", closeLayers);
      }
    },
  );

  /**
   * Cancel button closes the modal.
   */
  up.compiler(".up-modal-content #btn-cancel", function (link) {
    link.setAttribute("up-close", true);
  });

  /**
   * Entity edit forms reload both the form and the feedback section.
   * If no form present in the response, fallback to main.
   */
  up.compiler("form.em-form, form.em-list-form", function (form) {
    if (!form.hasAttribute("up-target")) {
      form.setAttribute("up-target", ".main");
      form.setAttribute("up-restore-scroll", false);
      form.setAttribute("up-history", true);
      form.setAttribute("up-cache", false);
    }
  });

  up.compiler("form.em-list-form", function (form) {
    form.setAttribute("up-autosubmit", true);
    form.setAttribute("up-delay", 100);
    form.setAttribute("up-restore-scroll", true);
    form.setAttribute("up-target", ".lists-results");
  });
}
