import { initializeCustomTagAutosuggests } from "./tags";

/**
 * Specific configuration for campaign tags on CompanyCampaignResponsibility association.
 * When tag components support configuration attributes, this method should be generalized to initialize <em>all</em> tag controls.
 */
EntityModule.registerInitializer(function (node) {
  $("[data-em-association='companyCampaignResponsibility.company']", node).each(function () {
    initializeCustomTagAutosuggests(
      '[data-em-association="companyCampaignResponsibility.company"] .form-group .tags-autosuggest',
      "LABEL",
    );
  });
});
