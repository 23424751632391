export function showSpinner(buttonId) {
  $(`${buttonId}.spinner`).addClass("show").prop("disabled", true);
}

export function hideSpinner(buttonId) {
  $(`${buttonId}.spinner`).removeClass("show").prop("disabled", false);
}

export function showSpinnerCenter(buttonId) {
  $(`${buttonId}.spinner-center`).addClass("show").prop("disabled", true);
  $(`${buttonId}.spinner-center`).html("");
}

export function hideSpinnerCenter(buttonId, text) {
  $(`${buttonId}.spinner-center`).removeClass("show").prop("disabled", false);
  $(`${buttonId}.spinner-center`).html("<span>" + text + "</span>");
}
