$(document).ready(function () {
  bindTranslationShowKey();
});

function bindTranslationShowKey() {
  $(document)
    .keydown(function (event) {
      if (event.key === "Control") {
        //ctrl
        displayAllCodes();
      }
    })
    .keyup(function (event) {
      if (event.key === "Control") {
        //ctrl
        displayAllTranslations();
      }
    });
}

function displayAllCodes() {
  $(".js-translated-element").each(function () {
    const $this = $(this);
    if (!$this.hasClass("showing-code")) {
      $this.html($this.attr("data-translation-code"));
      $this.addClass("showing-code");
    }
  });

  // This is a tryout for rendering codes in placeholders
  /*$( '.js-translated-attribute' ).each( function () {
      const $this = $( this );
      if ( !$this.hasClass( 'showing-code' ) ) {
        const attribute = $this.attr( 'data-translation-attribute' );
        $this.attr( attribute, $this.attr( 'data-translation-code' ) );
        $this.addClass( 'showing-code' );
      }
    } );*/
}

function displayAllTranslations() {
  $(".js-translated-element").each(function () {
    const $this = $(this);
    if ($this.hasClass("showing-code")) {
      $this.html($this.attr("data-translation-value"));
      $this.removeClass("showing-code");
    }
  });

  // This is a tryout for rendering codes in placeholders
  /*$( '.js-translated-attribute' ).each( function () {
      const $this = $( this );
      if ( $this.hasClass( 'showing-code' ) ) {
        const attribute = $this.attr( 'data-translation-attribute' );
        $this.attr( attribute, $this.attr( 'data-translation-value' ) );
        $this.removeClass( 'showing-code' );
      }
    } );*/
}
