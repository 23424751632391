EntityModule.registerInitializer(function (node) {
  $(".js-period-input-amount").on("change", function (event) {
    updatePeriodAmount(event.currentTarget, event.currentTarget.value);
  });

  $(".js-period-input-cardinality").on("change", function (event) {
    updatePeriodCardinality(event.currentTarget, event.currentTarget.value);
  });

  function updatePeriodAmount(target, amount) {
    var periodInput = $(target).parent().parent().find(".js-period-input");

    if (amount) {
      periodInput.attr("data-amount", amount);
      var cardinality = periodInput.attr("data-cardinality");
      var value = "P" + amount + cardinality;
      periodInput.val(value);
    } else {
      periodInput.val("");
    }
  }

  function updatePeriodCardinality(target, cardinality) {
    if (cardinality && cardinality !== "") {
      var periodInput = $(target).parent().parent().parent().find(".js-period-input");
      periodInput.attr("data-cardinality", cardinality);
      var amount = periodInput.attr("data-amount");
      var value = "P" + amount + cardinality;
      periodInput.val(value);
    }
  }
});
