EntityModule.registerInitializer(function (node) {
  $(".password-strength-calculator").keyup((e) => {
    const $element = $(e.target);
    const $passwordStrengthDescriptor = $element.siblings(".password-strength-view");
    const weakLabel = $passwordStrengthDescriptor.attr("data-label-weak");
    const averageLabel = $passwordStrengthDescriptor.attr("data-label-average");
    const strongLabel = $passwordStrengthDescriptor.attr("data-label-strong");
    const pwVal = $element.val();
    let strengthPoints = 0;
    if (pwVal.length >= 8) {
      strengthPoints++;
    }
    if (pwVal.length >= 12 && /\d{2,}/.test(pwVal) && /\w{2,}/.test(pwVal)) {
      strengthPoints++;
    }
    if (/\d/.test(pwVal)) {
      strengthPoints++;
    }
    if (/\w/.test(pwVal)) {
      strengthPoints++;
    }
    if (/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(pwVal)) {
      strengthPoints++;
    } else {
      strengthPoints--;
    }
    if (strengthPoints <= 0) {
      $element.siblings(".password-strength-view").html(`<span style="color: #e0e0e0">—</span>
                            <span style="color: #e0e0e0">—</span>
                            <span style="color: #e0e0e0">—</span>
                            <span class="text" style="color: #e0e0e0">${weakLabel}</span>`);
    } else if (strengthPoints === 1 || strengthPoints === 2 || strengthPoints === 3) {
      $element.siblings(".password-strength-view").html(`<span style="color: #ff5050">—</span>
                            <span style="color: #e0e0e0">—</span>
                            <span style="color: #e0e0e0">—</span>
                            <span class="text"  style="color: #ff5050">${weakLabel}</span>`);
    } else if (strengthPoints === 4) {
      $element.siblings(".password-strength-view").html(`<span style="color: #ff9800">—</span>
                            <span style="color: #ff9800">—</span>
                            <span style="color: #e0e0e0">—</span>
                            <span class="text"  style="color: #ff9800">${averageLabel}</span>`);
    } else if (strengthPoints === 5) {
      $element.siblings(".password-strength-view").html(`<span style="color: #4caf50">—</span>
                            <span style="color: #4caf50">—</span>
                            <span style="color: #4caf50">—</span>
                            <span class="text"  style="color: #4caf50">${strongLabel}</span>`);
    }
  });
});
