import { handleError } from "./utils";
import { initWaveSurfer } from "./wavesurfer";
import Chart from "chart.js";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import AudioPeakMeter from "web-audio-peak-meter";

Chart.plugins.register([ChartAnnotation]);

EntityModule.registerInitializer(function (node) {
  $(".js-audio-detail-button", node).on("click", function (e) {
    const assetLibraryEntryId = $(this).attr("data-asset-library-entry-id");
    const waveSurfer = $(".wave-surfer", node).prop("wavesurferInstance");
    if (waveSurfer !== undefined && waveSurfer !== null) {
      waveSurfer.pause();
    }
    showAudioDetailModal(assetLibraryEntryId);
  });
});

function calculateSpaceForNonAudioElements() {
  const PINK_BACKDROP = 20;
  const ASSET_INFO = 92;
  const PLAYER = 40;
  const WAVE_MARGIN = 20;
  const TIMELINE = 20;
  const MODAL_TITLE = 50;
  const MODAL_BORDER = 2;
  const LINE_BETWEEN_WAVES = 1;
  return PINK_BACKDROP + ASSET_INFO + PLAYER + WAVE_MARGIN + TIMELINE + MODAL_TITLE + MODAL_BORDER + LINE_BETWEEN_WAVES;
}

function showAudioDetailModal(assetLibraryEntryId) {
  fetch("/manager/audio-details?assetLibraryEntryId=" + assetLibraryEntryId)
    .then((data) => data.text())
    .then((data) => {
      $("#modal .modal-dialog").addClass("modal-xl modal-audio-detail");
      $("#modal .modal-content").html(data);
      $("#modal").modal("show");
      const spaceForNonAudioElements = calculateSpaceForNonAudioElements();
      const calculatedAudioSpace = window.innerHeight - spaceForNonAudioElements;
      const calculatedChartHeight = calculatedAudioSpace / 2;
      const topChartWrapper = $(".chart-wrapper-top");
      const bottomChartWrapper = $(".chart-wrapper-bottom");
      topChartWrapper.css("height", calculatedChartHeight);
      topChartWrapper.css("min-height", calculatedChartHeight);
      $("#chart-top").css("height", calculatedChartHeight);
      bottomChartWrapper.css("height", calculatedChartHeight);
      bottomChartWrapper.css("min-height", calculatedChartHeight);
      bottomChartWrapper.css("top", calculatedChartHeight);
      $("#chart-bottom").css("height", calculatedChartHeight);
      $("#peak-meter").css("height", calculatedAudioSpace);
      $(".audio-detail-wave-surfer", $("#modal .modal-content")).each(function () {
        const assetWaveId = $(this).attr("data-asset-waver");
        const duration = $(".wave", this).attr("data-asset-duration");
        var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        const wavesurfer = initWaveSurfer(
          this,
          duration,
          ".audio-detail-wave-surfer .wave[data-asset-waver='" + assetWaveId + "']",
          ".audio-detail-wave-surfer .wave-timeline",
          calculatedChartHeight,
          audioCtx,
        );
        const peakMeter = $("#modal #peak-meter").get(0);
        const audio = $("#modal audio").get(0);
        const sourceNode = audioCtx.createMediaElementSource(audio);
        sourceNode.connect(audioCtx.destination);
        const meterNode = AudioPeakMeter.createMeterNode(sourceNode, audioCtx);
        AudioPeakMeter.createMeter(peakMeter, meterNode, {
          borderSize: 4,
          fontSize: 12,
          backgroundColor: "white",
          tickColor: "#888",
          labelColor: "#888",
          //gradient: ['#dc3545 1%', '#ff0 16%', 'lime 45%','#4caf50 100%'],
          gradient: ["#dc3545 1%", "#ffc107 16%", "#4caf50 45%", "#28a745 100%"],
          dbRange: 48,
          dbTickSize: 6,
        });
        $("#modal").one("hidden.bs.modal", function () {
          meterNode.disconnect();
          sourceNode.disconnect();
          wavesurfer.destroy();
        });

        wavesurfer.on("waveform-ready", function () {
          const width = wavesurfer.mediaContainer.scrollWidth;
          let peaks = wavesurfer.backend.getPeaks(width, 0, width);
          let firstPeaks = peaks[0];
          let secondPeaks = peaks[1];
          let combinedMin = Math.min(wavesurfer.util.min(firstPeaks), wavesurfer.util.min(secondPeaks));
          let combinedMax = Math.max(wavesurfer.util.max(firstPeaks), wavesurfer.util.max(secondPeaks));
          drawChart("chart-top", combinedMin, combinedMax);
          drawChart("chart-bottom", combinedMin, combinedMax);
        });
      });
    })
    .catch(handleError);
}

function drawChart(elementId, min, max) {
  // const actualAbsoluteYAxisValue = Math.max(Math.abs(min), Math.abs(max));
  const actualAbsoluteYAxisValue = 1;
  var ctx = document.getElementById(elementId).getContext("2d");

  const vrtPreferredMaxPeak = 0.354813; // linear value for -9 dB
  var chart = new Chart(ctx, {
    type: "line",
    options: {
      maintainAspectRatio: false,
      annotation: {
        annotations: [
          {
            type: "line",
            mode: "horizontal",
            scaleID: "y-axis-0",
            value: vrtPreferredMaxPeak,
            borderWidth: 1,
            borderColor: "#C010C0",
            label: {
              enabled: false,
            },
          },
          {
            type: "line",
            mode: "horizontal",
            scaleID: "y-axis-0",
            value: -vrtPreferredMaxPeak,
            borderWidth: 1,
            borderColor: "#C010C0",
            label: {
              enabled: false,
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: false,
          },
        ],
        yAxes: [
          {
            ticks: {
              min: -actualAbsoluteYAxisValue,
              max: actualAbsoluteYAxisValue,
              precision: 2,
              stepSize: actualAbsoluteYAxisValue / 4.0, //this way we have 4 steps above, 4 steps below and we have exact step spacing
              callback: function (value) {
                const db = 20.0 * Math.log10(Math.abs(value));
                //const val = Math.round((db + Number.EPSILON) * 10) / 10;
                if (db === Infinity) {
                  return "Inf";
                }
                if (db === -Infinity) {
                  return "-Inf";
                }
                return (Math.round(db * 10.0) / 10.0).toFixed(1);
              },
            },
          },
        ],
      },
    },
  });
}
