EntityModule.registerInitializer(function (node) {
  configureWarningModal(node);
});

export function configureWarningModal(node) {
  $(".js-warning-modal", node)
    .off()
    .on("click", function (event) {
      warningModal($(this).attr("data-text-warning"));
    });
}

function warningModal(text) {
  $("#warningModal .modal-body-content").html(text);
  $("#warningModal").modal("show");
}
