EntityModule.registerInitializer(function (node) {
  $("[data-ajax-load]", node).each(function (node) {
    var settings = $(this).data("ajax-load");
    $(this).on("click", function (e) {
      e.preventDefault();
      var link = $(this);

      var dialog = $(
        '<div class="modal fade" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">\n' +
          '  <div class="modal-dialog modal-dialog-centered" role="document">\n' +
          '    <div class="modal-content">\n' +
          '<div class="modal-header">\n' +
          '        <h5 class="modal-title" id="staticBackdropLabel"></h5>\n' +
          '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n' +
          '          <span aria-hidden="true">&times;</span>\n' +
          "        </button>\n" +
          "      </div>" +
          "  </div>\n" +
          "</div>",
      ).modal();

      $.ajax({
        type: "get",
        url: link.attr("href"),
        headers: {
          "ax-em-partial": settings.partial,
          "ax-em-ajax-context": "dialog",
        },
      }).done(function (data, status, request) {
        $(".modal-title", dialog).html(request.getResponseHeader("ax-em-dialog-title"));
        $(".modal-header", dialog).after($(data));
        EntityModule.initializeFormElements(dialog);

        $("#btn-cancel", dialog).on("click", function (e) {
          e.preventDefault();
          dialog
            .on("hidden.bs.modal", function (e) {
              dialog.remove();
            })
            .modal("hide");
        });
      });
    });
  });
});
