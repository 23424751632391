EntityModule.registerInitializer(function (node) {
  initializeNavMenu();
});

function initializeNavMenu() {
  document.querySelectorAll(".top-nav .top-nav-2-opener").forEach((el) => {
    const anchor = el.querySelector("a");
    anchor.removeEventListener("click", toggleTopNav2);
    anchor.addEventListener("click", toggleTopNav2);
  });

  $("a").mousedown(function (e) {
    e.preventDefault();
  });
}

function toggleTopNav2(e) {
  const topNav2 = document.querySelector(".top-nav-2");
  e.preventDefault();
  topNav2.classList.toggle("visible");
}
