import i18next from "i18next";
import i18nextChainedBackend from "i18next-chained-backend";
import i18nextLocalStorageBackend from "i18next-localstorage-backend";
import i18nextHttpBackend from "i18next-http-backend";

const userLanguage = window.camalion.userLanguage;
const camalionStaticVersion = window.camalion.staticVersion;

const developmentMode = window.camalion.developmentMode;

let basePath = window.AcrossWebModule.staticPath;

i18next.use(i18nextChainedBackend).init({
  lng: userLanguage,
  fallbackLng: "en",
  load: "currentOnly",
  nsSeparator: false, // namespace separator
  keySeparator: false, // key separator
  backend: {
    backends: [
      i18nextLocalStorageBackend, // primary
      i18nextHttpBackend, // fallback
    ],
    backendOptions: [
      {
        prefix: "i18next_res_",
        defaultVersion: camalionStaticVersion,
        expirationTime: developmentMode ? 0 : 7 * 24 * 60 * 60 * 1000,
        store: window.localStorage,
      },
      {
        loadPath: basePath + "/" + camalionStaticVersion + "/camashop/i18n/{lng}/translations.json", // xhr load path for my own fallback
      },
    ],
  },
  interpolation: {
    prefix: "{",
    // prefix: '{[\\d]+',
    suffix: "}",
  },
});

export default i18next;
