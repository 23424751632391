import { showValidationErrorModal } from "./errormodal";

const $ = window.$;

$("#assetModal").on("show.bs.modal", function (e) {
  get(
    {
      type: "get",
      url: "/manager/entities/asset?view=assetList&_partial=content",
    },
    $(e.relatedTarget).data("campaign-asset-id"),
  );
});

$(document).on("click", ".linkAssetToCampaignInvalid", function (e) {
  showValidationErrorModal(e.currentTarget.getAttribute("data-validation-modal-content"), true);
});

function get(formConfig, campaignAssetId) {
  $.ajax(formConfig).done(function (data) {
    var body = $(document).find(".modal-body");
    body.html(data);
    ajaxify(body, campaignAssetId);
  });
}

var ajaxify = function (node, campaignAssetId) {
  EntityModule.initializeFormElements(node);

  $("form", node).on("submit", function (e) {
    e.preventDefault();
    e.stopPropagation();

    get({
      type: "get",
      url: "/manager/entities/asset?view=assetList&_partial=content",
      data: $(this).serialize(),
    });
  });

  const selectAssetBtn = $(".js-submit-asset");
  selectAssetBtn.prop("disabled", false);
  selectAssetBtn.on("click", function (e) {
    $(this).prop("disabled", true);
    $(this).find(".loading").removeClass("d-none");

    var selectedCard = $("#assetModal").find(".card.selected-asset ");
    var selectedAssetId = selectedCard.data("asset-id");

    var linkingUrl = "/manager/assets/" + campaignAssetId + "/link/" + selectedAssetId;
    var csrf = $('[name="_csrf"]').val();

    $.ajax({
      type: "POST",
      url: linkingUrl,
      data: { _csrf: csrf },
      success: function (e) {
        window.location.reload();
      },
      fail: function (xhr, textStatus, errorThrown) {
        alert("Failed linking the asset to the campaignRequirement. Please try again");
      },
    });
  });
};

let propertySorter = {
  property: undefined,
  direction: undefined,
};

$(document).on("mouseover", '.js-asset-library:not(.grouped-table) thead th:not(".sortable")', function (event) {
  $(event.target).css("color", "#0b0b0b");
  $(event.target).css("cursor", "pointer");
});

$(document).on("mouseleave", '.js-asset-library:not(.grouped-table) thead th:not(".sortable")', function (event) {
  $(event.target).css("color", "#888");
});

$(document).on("click", '.js-asset-library:not(.grouped-table) thead th:not(".sortable")', function (event) {
  const currentHeading = $(event.target);
  const propertyToSort = currentHeading.data("tbl-field");

  if (propertySorter.property === propertyToSort) {
    propertySorter.direction = propertySorter.direction === "ASC" ? "DESC" : "ASC";
  } else {
    propertySorter = {
      property: propertyToSort,
      direction: "ASC",
    };
  }

  const inverse = propertySorter.direction === "DESC";

  $(".js-asset-library:not(.grouped-table) tbody")
    .find("td")
    .filter(function () {
      return $(this).data("tbl-field") === propertySorter.property;
    })
    .sortElements(
      function (a, b) {
        return $.text([a]) > $.text([b]) ? (inverse ? -1 : 1) : inverse ? 1 : -1;
      },
      function () {
        return this.parentNode;
      },
    );

  $(".js-asset-library:not(.grouped-table) thead th").removeClass("asc desc pseudo-sortable");

  currentHeading.addClass("pseudo-sortable " + propertySorter.direction.toLowerCase());
});

$(document).on("click", ".js-select-asset", function (e) {
  var card = $(e.currentTarget);
  $(".js-select-asset").removeClass("selected-asset");
  card.addClass("selected-asset");

  $(".js-submit-asset").removeClass("disabled");
});
